<template>
    <div class="notifications">
        {{propsToData}}
        <span mode="in-out">
            <div
                data-notify="container"
                role="alert"
                v-on:click="loading"
                data-notify-position="top-center"
                :class="'alert-custom open alert-with-icon bottom right alert-success-custom_ notification-bottom ' + classAlert "
            >
                <span
                    data-notify="icon"
                    :class="icon"
                >
                </span>
                <div data-notify="message">
                    <div>{{cargado}} / {{total}}</div>
                </div>
            </div>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        total: {
            type: Number,
            default: 0,
        },

        count: {
            type: Number,
            default: 0,
        }
    },

    data() {
        return {
            icon: "pi pi-spin pi-spinner",
            cargado: 0,
            classAlert: "",
        }
    },

    computed: {
        propsToData() {
            this.cargado = this.count;
            this.cargando = this.loading;
        }
    },

    watch: {
        cargado: function(newVal, oldVal) {
            if(newVal > 0) {
                return this.classAlert = "loading-alert"
            }

            return this.classAlert = "loading-alert-reverse"
        }
    },

    methods: {
        loading() {
            this.classAlert = this.classAlert == "loading-alert-reverse" || this.classAlert == "" ? "loading-alert" : "loading-alert-reverse";
        },
    }
};
</script>
<style lang="scss">
    .alert-custom > div {
        display: flex;
        justify-content: flex-end;
        padding-left: 32px;
    }

    .alert-custom > div > div {
        white-space: nowrap;
        user-select: none;
    }

    .alert-custom {
        cursor: pointer;
        transition: all 0.4s;
        overflow: hidden;
        max-width: 500px;
        display: flex;
        border: 0;
        color: #ffffff;
        padding: 0.9rem 1.25rem;
        margin-bottom: 1rem;
        position: fixed;
        z-index: 10000;
        width: 500px;
        border-radius: 0.2857rem;
    }

    .alert-custom > span {
        font-size: 22px;
        display: block;
        left: 19px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
    }

    .notifications .alert-custom.right {
        right: 20px;
    }

    .loading-alert {
        animation: widthRight 1s forwards;
    }

    .loading-alert-reverse {
        animation: widthRighrev 1s forwards;
    }

    @keyframes widthRight {        
        to {
            width: 161px;
        }

        from {
            width: 500px;
        }
    }

    @keyframes widthRighrev {
        to {
            width: 500px;
        }

        from {
            width: 161px;
        }
    }
</style>
