import XLSX from "xlsx";

const IndexDB = {
  createDB: async function (cb, storeName, nameDB, index, indexArray, unique = false, storeConfig) {
    const dbs = await window.indexedDB.databases()

    console.log(dbs.length)

    if(dbs.length == 0) {
      const request = window.indexedDB.open(nameDB)

      request.onupgradeneeded = function (event) {
        const db = event.target.result
        const objectStore = db.createObjectStore(storeName, storeConfig)
        
        objectStore.createIndex(index, indexArray, { unique })
      }

      request.onsuccess = function (e) {
        console.log(e)
        request.result.close()
      }

      return
    }

    this.getDbVersion(nameDB, (version) => {
      const versionIncrement = version + 1

      console.log(versionIncrement)

      this.incrementStore(cb, storeName, nameDB, index, indexArray, unique = false, storeConfig, versionIncrement)
    })
  },

  incrementStore: function (cb, storeName, nameDB, index, indexArray, unique = false, storeConfig, version) {
    var request = window.indexedDB.open(nameDB, version)

    request.onupgradeneeded = function (event) {
      const db = event.target.result
      const storesNames = Object.assign([], db.objectStoreNames)
      const existsStore = storesNames.filter((store) => store == storeName).length > 0

      if(existsStore) return

      const objectStore = db.createObjectStore(storeName, storeConfig)

      objectStore.createIndex(index, indexArray, { unique })
    }

    request.onsuccess = function (event) {
      request.result.close()
    }

    request.onerror = function (event) {
      console.log(event)
      request.result.close()
    }
  },

  getDbVersion: function (nameDB, cb) {
    var request = window.indexedDB.open(nameDB)

    request.onsuccess = function (event) {
      const version = event.target.result.version

      request.result.close()

      return cb(version)
    }
  },

  itemADD: async function (obj, callback, storeName, nameDB) {
    this.getDbVersion(nameDB, function (version) {
      var req = window.indexedDB.open(nameDB, version)
      
      req.onsuccess = function (e) {
        var db = req.result

        console.log(e)

        var trans = db.transaction([`${storeName}`], 'readwrite')
        trans.oncomplete = function (e) {
          callback();
        }

        var objectStore = trans.objectStore(storeName)
        objectStore.add(obj)
      }

      req.onerror = function (e) {
        console.log(e)
      }
    })
  },

  removeID: async function (id, storeName, nameDB) {
    this.getDbVersion(nameDB, function (version) {
      var req = window.indexedDB.open(nameDB, version)

      req.onsuccess = function (e) {
        var db = req.result
        var trans = db.transaction([`${storeName}`], 'readwrite')
        var store = trans.objectStore(storeName)
        store.delete(id)
      }
    })
  },

  getPagination: async function (offset, limit, cb, total_dados, storeName, nameDB, returnTotal = false, index) {

    this.getDbVersion(nameDB, function (version) {
      var req = window.indexedDB.open(nameDB, version)
      var total_pages = total_dados / limit;

      total_pages = total_pages > Math.round(total_pages) ? Math.round(total_pages) + 1 : Math.round(total_pages);
      total_pages = total_pages == 0 ? 1 : total_pages;

      req.onsuccess = function (e) {
        var DB = req.result;
        var trans = DB.transaction([`${storeName}`], 'readwrite');
        var objectStore = trans.objectStore(storeName);
        var results = [];
        var i = 0;
        var advanced = false;
        console.log(objectStore, index)
        var indexStore = objectStore.index(index);

        indexStore.openCursor().onsuccess = function (event) {

          var cursor = event.target.result;

          if (!cursor) {
            cb(results, offset, total_pages, returnTotal);
          } else {
            if (!advanced && offset !== 0) {
              advanced = true;
              cursor.advance(offset);
            } else if (cursor && i < limit) {
              results.push(cursor.value);
              i += 1;
              cursor.continue();
            } else {
              cb(results, offset, total_pages, returnTotal);
            }
          }
        }
      };
    })
  },

  clearStore: async function (storeName, nameDB) {

    this.getDbVersion(nameDB, function (version) {
      var req = window.indexedDB.open(nameDB, version)

      req.onsuccess = function (e) {
        var db = req.result;

        var trans = db.transaction([`${storeName}`], "readwrite");
        var store = trans.objectStore(storeName);
        store.clear();
      }
    })

  },

  exportExcel: function (name, storeName, nameDB, cols, props) {
    this.getDbVersion(nameDB, function (version) {
      const wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Lexart - Tercer OJO",
        Subject: "",
        Author: "Lexart",
        CreatedDate: new Date(),
      };
      wb.SheetNames.push("historico");

      var ws_data = [];
      ws_data.push(cols);

      var req = window.indexedDB.open(nameDB, version)

      req.onsuccess = function (e) {
        var db = req.result;

        var trans = db.transaction([`${storeName}`]);
        var store = trans.objectStore(storeName);
        var request = store.getAll();

        request.onsuccess = async function (event) {
          await request.result.map((item) => {
            var col = []
            props.map((prop) => {
              col.push(item[prop])
            })

            ws_data.push(col);
          });

          var ws = XLSX.utils.aoa_to_sheet(ws_data);
          wb.Sheets["historico"] = ws;

          var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }

          saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), name + '.xlsx');
        }

        request.oncomplete = async function (event) {
          await request.result.map((item) => {
            var col = [];

            props.map((prop) => {
              col.push(item[prop])
            })

            ws_data.push(col);
          });

          var ws = XLSX.utils.aoa_to_sheet(ws_data);
          wb.Sheets["historico"] = ws;

          var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }

          saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), date_desde + ' to ' + date_hasta + ' Historico de precios.xlsx');
        }
      }
    })
  }
}

export default IndexDB
